import Cookies from 'universal-cookie';
import config from './config.json';


// Use dummy function as default
export default function() {
    console.log('You shouldn\'t be here')
}

/////////////////////////////////
// Common Values functionality //
/////////////////////////////////

export function getEndpoint() {
    let endpoint = window.location.host
    if (window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.')) {
        console.log("Running in development mode, targeting dev endpoint")
        endpoint = config.endpoint;
    }
    return endpoint
}

//////////////////////////
// Cookie functionality //
//////////////////////////
let cookies = new Cookies();

export function setCookie(name, value, duration=2, path='/') {
    let date = new Date();
    date.setHours(date.getHours() + duration);
    cookies.set(name, value, {path: path, expires: date});
}

export function getCookie(name) {
    return cookies.get(name);
}

export function removeCookie(name) {
    cookies.remove(name)
}


export function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
}

export function newGuid() {
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}
 


