import React, { useState } from 'react'
import Base from '../components/Base'
import MUIForm from '../components/MUIForm'
import MUIFormSection from '../components/MUIFormSection'
import { Button, Grid, TextField } from '@material-ui/core'

export default function () {
    const [newRoom, setNewRoom] = useState('')

    const handleNewRoomNavigate = (event) => {
        window.location.pathname = "/" + newRoom
    }

    return (
        <Base>
            <MUIForm>
                <MUIFormSection>
                    <h2>Start an EVote!</h2>
                    <Grid container spacing={3} alignItems="center" justify="center">
                        <Grid item xs={12} md={3}>
                            <TextField
                                label={"New room name"}
                                value={newRoom}
                                onChange={(e) => {
                                    setNewRoom(e.target.value)
                                }}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleNewRoomNavigate(e)
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </MUIFormSection>
                <MUIFormSection>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={false}
                                    onClick={handleNewRoomNavigate}
                                >
                                    Create Room
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </MUIFormSection>
            </MUIForm>
        </Base>
        
    )
}
